<template>
  <layout v-if="blog" :loading="loading">
    <h2 class="mb-4">{{ blog.title }}</h2>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <img class="image" :src="url + blog.preview" alt="">
            <flipbook class="flipbook" :pages="[url + blog.file]"></flipbook>
            <span v-for="(tag) in blog.tags" :key="tag.id" class="badge badge-secondary">{{ tag.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </layout>
</template>
<script>
import Flipbook from 'flipbook-vue'
/**
 * Editors component
 */
export default {
  components: {
    Flipbook
  },
  data() {
    return {
      url: process.env.VUE_APP_IMAGE_URL
    };
  },
  computed: {
    blog() {
      return this.$store.getters['blogs/blog'];
    },
    loading() {
      return this.$store.state.blogs.loading;
    }
  },
  methods: {
  },
  mounted() {
    this.$store.dispatch('blogs/getBlog', this.$route.params.id);
  }
};
</script>

<style>
.image {
  max-width: 200px;
}
</style>
